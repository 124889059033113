<script setup lang="ts">
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import UhSpinner from '~/components/general/uhSpinner/UhSpinner.vue';
import type { User } from '~/types/user';
import LivePropertyModal from '~/components/navigation/appSidebar/LivePropertiesModal.vue';
import LivePropertiesIndicatorBar from '~/components/navigation/appSidebar/LivePropertiesIndicatorBar.vue';
import { useMainStore } from '~/stores/mainStore';

const openModal = ref<boolean>(false);

function toggleModal(): void {
  openModal.value = !openModal.value;
}

const { user }: { user: User } = useAuth();
const { livePropertiesCount, propertiesCount } = storeToRefs(useMainStore());
</script>

<template>
  <div>
    <button
      type="button"
      class="group mb-0 w-full rounded-lg border border-gray-200 bg-white p-4 shadow-sm hover:bg-white dark:border-gray-500"
      @click="toggleModal"
    >
      <span
        class="mb-4 block text-left font-bold text-gray-900 dark:text-white"
      >
        {{ user?.landlord?.company }}
      </span>

      <template v-if="!livePropertiesCount.pending">
        <div class="mb-2 flex justify-between">
          <span class="mr-4 text-sm font-medium text-gray-500 dark:text-white">
            Live properties
          </span>
          <span class="text-sm font-medium text-gray-500 dark:text-white">
            {{ livePropertiesCount.live.toLocaleString() }}/{{
              livePropertiesCount.limit.toLocaleString()
            }}
          </span>
        </div>

        <LivePropertiesIndicatorBar />
      </template>

      <template v-else>
        <UhSpinner size="8" />
      </template>
    </button>

    <LivePropertyModal
      :open="openModal"
      :properties-limit="livePropertiesCount.limit"
      :properties-live="livePropertiesCount.live"
      :properties-count="propertiesCount"
      @toggle-modal="toggleModal"
    />
  </div>
</template>
